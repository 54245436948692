<template>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="card form-body">
        <div class="card-body">
          <form>
            <div class="d-flex">
              <legend><h4>Abbreviation Information</h4></legend>

              <div class="form-group mr-5" style="width:50%">
                <label for="">Show Payment Option</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="show_payment_option"
                    class="switch"
                    id="show_payment_option_switch"
                  />
                  <label for="show_payment_option_switch"></label>
                </span>
                <label
                  for=""
                  class="text-danger"
                  v-if="show_payment_option == false"
                  >Note: if disabled bill to company</label
                >
                <!-- <label for="" v-else>Hide Payment Option</label> -->
              </div>

              <div class="form-group">
                <label for="">Active</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="is_active"
                    class="switch"
                    id="status_switch"
                  />
                  <label for="status_switch"></label>
                </span>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for=""
                    >Company <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="master_company_id"
                  >
                    <option value="">Select Company</option>
                    <option
                      v-for="(item, index) in getMasterCompanies"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.company_name }}
                    </option>
                  </select>
                  <!-- <input
                    v-model="company_name"
                    type="text"
                    class="form-control"
                    :class="
                      errors['company_name'] ? 'border border-danger' : ''
                    "
                  /> -->
                  <span
                    v-if="errors['master_company_id']"
                    class="text-danger"
                    >{{ errors["master_company_id"][0] }}</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for=""
                    >Abbreviation code <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="abbreviation_code"
                    type="text"
                    class="form-control"
                    :class="
                      errors['abbreviation_code'] ? 'border border-danger' : ''
                    "
                  />
                  <span
                    v-if="errors['abbreviation_code']"
                    class="text-danger"
                    >{{ errors["abbreviation_code"][0] }}</span
                  >
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Quota <span class="text-danger">*</span></label>
                  <input
                    v-model="quota"
                    type="number"
                    min="0"
                    class="form-control"
                    :class="errors['quota'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['quota']" class="text-danger">{{
                    errors["quota"][0]
                  }}</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for=""
                    >Expiration date <span class="text-danger">*</span></label
                  >

                  <date-picker
                    valueType="MM/DD/YYYY"
                    v-model="expireDate"
                    format="MM/DD/YYYY"
                    :disabled-date="notAfterToday"
                    class="form_control"
                    range
                  ></date-picker>
                  <span v-if="errors['valid_from']" class="text-danger">{{
                    errors["valid_from"][0]
                  }}</span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success"
            @click="update('kt_update_company_data')"
            ref="kt_update_company_data"
            style="float: right"
            v-if="isEdit"
          >
            <i class="fa fa-check"></i> Update
          </button>

          <button
            class="btn btn-success ml-3"
            style="float: right"
            v-if="!isEdit"
            ref="kt_save_company"
            @click="store('S', 'kt_save_company')"
          >
            <i class="fa fa-check"></i> Save
          </button>
          <button
            ref="kt_save_add_another_company"
            class="btn btn-success"
            @click="store('SAA', 'kt_save_add_another_company')"
            style="float: right"
            v-if="!isEdit"
          >
            <i class="fa fa-check"></i> Save and add another
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import {
  STORE_COMPANY,
  GET_COMPANY_DATA,
  UPDATE_COMPANY,
  FETCH_MASTER_COMPANIES
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      isEdit: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Abbreviations",
          route: "client.company"
        },
        {
          id: 3,
          title: "Create New Abbreviation",
          route: ""
        }
      ],
      company_name: "",
      abbreviation_code: "",
      quota: 1,
      master_company_id: "",
      address: "",
      contact_name: "",
      contact_email: "",
      contact_phone_no: "",
      show_payment_option: false,
      is_active: true,
      expireDate: "",
      errors: []
    };
  },
  computed: {
    ...mapGetters(["getMasterCompanies", "currentUser"])
  },

  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_MASTER_COMPANIES);

    this.isEdit = this.$route.name == "client.company.edit" ? true : false;
    var id = this.$route.params.id;
    if (this.isEdit) {
      this.breadCrumbs[2].title = "";
      this.$store
        .dispatch(GET_COMPANY_DATA, id)
        .then(data => {
          this.breadCrumbs[2].title = "Edit | " + data.company_name;
          this.company_name = data.company_name;
          this.abbreviation_code = data.abbreviation_code;
          this.address = data.address;
          this.contact_name = data.contact_name;
          this.contact_email = data.contact_email;
          this.contact_phone_no = data.contact_phone_number;
          this.is_active = data.is_active;
          this.master_company_id = data.master_company;
          this.quota = data.quota;
          this.show_payment_option = data.show_payment_option;
          this.expireDate = [
            this.$moment(data.valid_from).format("MM/DD/YYYY"),
            this.$moment(data.valid_to).format("MM/DD/YYYY")
          ];
        })
        .catch(() => {
          this.$toastr.e("Company detail not found!");
          this.$router.push({ name: "client.company" });
        });
    }
  },
  methods: {
    notAfterToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    goBack() {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.company",
        params: { client_slug: client_slug }
      });
    },
    clearForm() {
      this.quota = 0;
      this.company_name = "";
      this.abbreviation_code = "";
      this.address = "";
      this.contact_name = "";
      this.contact_email = "";
      this.contact_phone_no = "";
      this.master_company_id = "";
      this.is_active = false;
      this.expireDate = "";
      this.show_payment_option = false;
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    update(ref) {
      var valid_from = "";
      var valid_to = "";
      this.expireDate = this.expireDate == "," ? "" : this.expireDate;
      if (this.expireDate != "") {
        var exp = this.expireDate;
        valid_from = this.$moment(exp[0]).format("YYYY-MM-DD");
        valid_to = this.$moment(exp[1]).format("YYYY-MM-DD");
      }
      this.errors = [];
      this.loadingButton(ref);
      var data_ = {
        company_name: this.company_name,
        address: this.address,
        abbreviation_code: this.abbreviation_code.replace(/\s/g, ""),
        contact_name: this.contact_name,
        contact_email: this.contact_email,
        contact_phone_number: this.contact_phone_no,
        master_company_id: this.master_company_id,
        is_active: this.is_active,
        quota: this.quota,
        valid_from: valid_from,
        valid_to: valid_to,
        show_payment_option: this.show_payment_option
      };
      this.$store
        .dispatch(UPDATE_COMPANY, {
          id: this.$route.params.id,
          data: data_
        })
        .then(data => {
          if (data.code == 200) {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            this.$router.push({ name: "client.company" });
          } else {
            this.$toastr.w(data.msg);
            this.closeBtnLoad(ref);
          }
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },
    store(saveType, ref) {
      var valid_from = "";
      var valid_to = "";
      this.expireDate = this.expireDate == "," ? "" : this.expireDate;
      if (this.expireDate != "") {
        var exp = this.expireDate;
        valid_from = this.$moment(exp[0]).format("YYYY-MM-DD");
        valid_to = this.$moment(exp[1]).format("YYYY-MM-DD");
      }
      this.errors = [];
      this.loadingButton(ref);
      var data_ = {
        company_name: this.company_name,
        address: this.address,
        abbreviation_code: this.abbreviation_code.replace(/\s/g, ""),
        quota: this.quota,
        contact_name: this.contact_name,
        contact_email: this.contact_email,
        contact_phone_number: this.contact_phone_no,
        is_active: this.is_active,
        master_company_id: this.master_company_id,
        valid_from: valid_from,
        valid_to: valid_to,
        show_payment_option: this.show_payment_option
      };
      this.$store
        .dispatch(STORE_COMPANY, data_)
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
          if (saveType == "S") {
            this.$router.push({ name: "client.company" });
          } else if (saveType == "SAA") {
            this.clearForm();
          }
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
